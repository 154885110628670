<template>
    <div>
        <div class="topimage">
            <el-image style="width:100%" :src="require('@/assets/guanyu/bs_banner'+($i18n.locale == 'zh' ? '' : '_'+$i18n.locale)+'.jpg')"></el-image>
        </div>
        <div class="appmain">
            <div>
                <el-row :gutter="30" style="margin-top:50px;">
                    <el-col :span="12">
                        <el-image style="100%" :src="require('@/assets/biaoshi/logo1.png')"></el-image>
                    </el-col>
                    <el-col :span="12">
                        <el-image style="100%" :src="require('@/assets/biaoshi/logo2.png')"></el-image>
                    </el-col>
                </el-row>
            </div>
            <div class="title">
                {{$t('biaoshi.title')}}
            </div>
            <div class="content">
                {{$t('biaoshi.content')}}
            </div>
            <!-- <div class="title">{{$t('biaoshi.standardColors')}}</div>
            <div class="gslan">
                <div class="bztitle">{{$t('biaoshi.brandStandardColor')}}</div>
                <div class="bzsezhi">
                    <div>PANTONE：2132C</div>
                    <div>CMYK：100-65-0-0</div>
                    <div>RGB：0-82-217</div>
                    <div>#0052D9</div>
                </div>
            </div>
            <div>
                <el-row :gutter="50">
                    <el-col :span="12">
                        <div class="title">{{$t('biaoshi.levels')}}</div>
                        <div class="sjimage" style="margin-top:20px;">
                            <el-image style="width:100%" :src="require('@/assets/biaoshi/sejie.png')"></el-image>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="title">{{$t('biaoshi.auxiliaryColor')}}</div>
                        <div class="fzimage" style="margin-top:20px;">
                            <el-image style="width:100%" :src="require('@/assets/biaoshi/fuzhuse.png')"></el-image>
                        </div>
                        <div class="fzcontent">
                            {{$t('biaoshi.fuzhu')}}
                        </div>
                    </el-col>
                </el-row>
            </div> -->
            <div style="margin-top:30px">
                <el-row :gutter="50">
                    <el-col :span="12">
                        <div class="title" style="margin-top:0px;">{{$t('biaoshi.mascot')}}</div>
                        <div class="jxcontent">
                            {{$t('biaoshi.jxyy')}}
                        </div>
                        <!-- <div class="title" style="margin-top:50px">{{$t('biaoshi.annotation')}}</div> -->
                        <div class="jxcontent">
                            <div>{{$t('biaoshi.name')}}</div>
                            <div>{{$t('biaoshi.name2')}}</div>
                            <div>{{$t('biaoshi.name3')}}</div>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="gouimage">
                            <el-image style="width:100%" :src="require('@/assets/biaoshi/xiaogou.png')"></el-image>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div>
                <el-image style="width:100%" :src="require('@/assets/biaoshi/jixiangwu.png')"></el-image>
            </div>
            <div>
                <div class="title">{{$t('biaoshi.logo')}}</div>
                <div class="lglist">
                    <el-row :gutter="30">
                        <el-col :span="8" v-for="(item,index) in list" :key="index">
                            <div class="lgitem">
                                <el-image :src= "item" style="border: 1px solid #C0BDBD;"></el-image>
                                <div class="xiazai" @click="downs(index)">
                                    <i class="el-icon-download"></i><span style="margin-left:5px">{{$t('biaoshi.Download')}}</span>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            list:{
                "logo1":require('@/assets/biaoshi/xiazai1.png'),
                "logo2":require('@/assets/biaoshi/xiazai2.png'),
                "logo3":require('@/assets/biaoshi/xiazai3.png'),
            },
            title:''
        }
    },
    metaInfo () {
        return {
        title: this.title,
        meta: [
            {
                name: 'keyWords',
                content: this.$t('seo.keywords')
            },
            {
                name: 'description',
                content: this.$t('seo.description')
            }
        ]
        }
    },
    created(){
        this.title = this.$t('layout.logo')+'-'+this.$t('seo.title')
    },
    methods:{
        downs(index) {
            let href = ''
            if(index == 'logo1'){
                href = process.env.VUE_APP_BASE_API+'data/logo/SmartGen_cn_trademark.ai'
            }else if(index == 'logo2'){
                href = process.env.VUE_APP_BASE_API+'data/logo/SmartGen_en_trademark.ai'
            }else if(index == 'logo3'){
                href = process.env.VUE_APP_BASE_API+'data/logo/SmartGen_trademark_slogan.ai'
            }
            window.location.href = href
        },
    }
}
</script>
<style lang="scss" scoped>
    .appmain{
        width: 70%;
        margin-top: 1%;
    }
    @media screen and (max-width: 480px) {
        .appmain{
            width: 100%;
        }
    }
    .title{
        margin-top: 3%;
        font-size: 24px;
        font-family: SourceHanSansCN-Bold;
        font-weight: bold;
        color: #333333;
    }
    .content{
        font-size: 16px;
        font-family: SourceHanSansCN-Regular;
        font-weight: 400;
        color: #333333;
        margin-top: 2%;
    }
    .gslan{
        margin-top: 3%;
        background: #0052D9;
        height: 290px;
        .bztitle{
            margin-left: 20px;
            padding-top: 30px;
            font-size: 24px;
            font-family: SourceHanSansCN-Bold;
            font-weight: bold;
            color: #FFFFFF;
        }
        .bzsezhi{
            margin-top: 8%;
            margin-left: 20px;
            font-size: 14px;
            font-family: SourceHanSansCN;
            color: #FFFFFF;
            div{
                margin-bottom: 5px;
            }
        }
    }
    .fzcontent{
        font-size: 16px;
        font-family: SourceHanSansCN-Regular;
        font-weight: 400;
        color: #333333;
        margin-top: 20px;
        line-height: 30px;
    }
    .jxcontent{
        margin-top: 10px;
        font-size: 16px;
        font-family: SourceHanSansCN-Regular;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
    }
    .lglist{
        margin-top: 20px;
        .lgitem{
            width: 100%;
            height: 136px;
            background-size: 100% 100%;
            cursor: pointer;
            position: relative;
            .xiazai{
                width: 100%;
                height: 98%;
                background: #FFFFFF;
                opacity: 0.9;
                position: absolute;
                top: 0px;
                text-align: center;
                line-height: 160px;
                border: 1px solid #0052D9;
                color: #0052D9;
                font-weight: bold;
                display: none;
            }
        }
        .lgitem:hover .xiazai{
            display: block;
        }
    }
</style>